import * as React from 'react'
import Layout from '../../components/layout'
import SEO from '../../components/seo'
import { scrollHandler } from '../../utils/functions'
import { graphql } from 'gatsby'


type Props = {
  item?: any,
  errors?: string,
  data: any
}

class About extends React.Component<Props> {

  componentDidMount() {
    scrollHandler();    
  }

  renderPosts() {
    
    const { data } = this.props;
    let post = data.wordpressPage;
    let images = post.acf.works_images;
    let imageAry = [];
    
    let featured_image = !post.featured_image_src || post.featured_image_src == 0 ? '' : post.featured_image_src.replace('/yjrimsk/image/upload/', '/yjrimsk/image/upload/q_auto/');

    if(images.length){

      for(var i in images){
        let image = images[i].works_images_image.url.replace('/yjrimsk/image/upload/', '/yjrimsk/image/upload/q_auto/')
        imageAry.push(
          <div className="image" key={i}>
            <img src={image} alt=""/>
          </div>
        );
      }      

    }

    return (
      <div className="container">
        <h1 className="page-title">{post.title}</h1>
        <div className="page-contents">
          <div className="cols">
            <div className="col">
              {featured_image && <img src={featured_image} alt=""/> }
            </div>
            <div className="col">
              <div dangerouslySetInnerHTML={{ __html: post.content }} />
            </div>
          </div>                  
          <div className="images">
            {imageAry}
          </div>  
        </div>        
      </div>
    )

  }

  renderEmpty() {
      return (
          <div>not found</div>
      )
  }  

  render() {
    const { data, errors } = this.props;
    // if (errors) {
    //   return (
    //     <Layout title={`Error | Next.js + TypeScript Example`}>
    //       <p>
    //         <span style={{ color: 'red' }}>Error:</span> {errors}
    //       </p>
    //     </Layout>
    //   )
    // }

    return (
      <Layout>
        <SEO title={`${
          data ? data.wordpressPage.title : 'About'
        }`} />
        <section className="about-section section">
            {!errors ?
                this.renderPosts() :
                this.renderEmpty()
            }
        </section>
      </Layout>
    )
  }
}

export default About



export const query = graphql`
      query {
        wordpressPage(slug: {eq: "about"}) {
        acf {
          works_images {
            works_images_image_caption
            works_images_image_number_of_columns
            works_images_image {
              url
            }
          }
        }
        featured_image_src
        title
        slug
        content
      }
    }

  `;